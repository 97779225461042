import React from 'react'
import { useTheme } from '../../themeContext'
import { ClothingProps } from './types'
import { Noop } from '../../utils/Noop'

export const Shirt = ({ color, graphic: Graphic = Noop }: ClothingProps) => {
  const { colors, skin } = useTheme()

  const { base, shadow } = colors.clothing[color]

  return (
    <>
      <path
        d="M677.8,906.35c.21-37.11-1.21-70.07-7.73-95.39C660,785.6,641.89,770.48,610,770.48L632.79,905S655.31,915.24,677.8,906.35Z"
        fill={skin.shadow}
      />
      <path
        d="M686.41,895.79a20.31,20.31,0,0,1-3.7,2.43"
        fill="none"
        stroke={colors.outline}
        strokeLinecap="square"
        strokeMiterlimit={10}
        strokeWidth="12px"
      />
      <path
        d="M326.11,809.88c-6.83,25.57-8,59.13-7.74,97,19.9,8.54,50.42-2.15,50.42-2.15l17.33-132.24C355.25,772.46,336.85,786.36,326.11,809.88Z"
        fill={skin.shadow}
      />
      <path
        d="M613.76,758.72H559.55v.1a62.78,62.78,0,0,1-1.44,13.39,60.92,60.92,0,0,1-1.74,6.33,50.88,50.88,0,0,1-10,16.83,48.2,48.2,0,0,1-4.5,4.43c-25.17,18.2-65.17,19.2-89.48-.58a51.79,51.79,0,0,1-7.11-7.6,50.84,50.84,0,0,1-9.1-19.41,62.41,62.41,0,0,1-1.07-6.59,61,61,0,0,1-.37-6.8v-.1H380.53A13.28,13.28,0,0,0,367.26,772v284.68A13.28,13.28,0,0,0,380.53,1070H619.47a13.28,13.28,0,0,0,13.27-13.28V778.6C632.74,766.38,626.85,758.72,613.76,758.72Z"
        fill={base}
      />
      <path
        d="M610,758.72c63.54,0,73.4,56,73.9,133.17-18.6,11.62-48.91,1.31-48.91,1.31Z"
        fill={base}
      />
      <path
        d="M632.74,831.87,610,870l11.38,130h11.61V894.35l17.28,2.89C644.6,825.07,632.74,831.87,632.74,831.87Z"
        fill={shadow}
      />
      <path
        d="M386.12,758.72c-61.78,0-75.83,53.8-76.85,127.66,19,19.86,58,4.58,58,4.58Z"
        fill={base}
      />
      <path
        d="M367.23,831.87V891l-17.31,4.31C355.59,825.19,367.23,831.87,367.23,831.87Z"
        fill={shadow}
      />
      <path
        d="M610,758.72c67.41,0,78.45,56.29,78.9,133.78-26.2,12.43-53.91.7-53.91.7"
        fill="none"
        stroke={colors.outline}
        strokeLinecap="square"
        strokeMiterlimit={10}
        strokeWidth="12px"
      />
      <path
        d="M386.12,758.72c-66.79,0-80.08,54.7-80.76,130.72C326,904.22,367.22,891,367.22,891"
        fill="none"
        stroke={colors.outline}
        strokeLinecap="square"
        strokeMiterlimit={10}
        strokeWidth="12px"
      />
      <path
        d="M380.53,1070H497.15C388,1070,396.24,838.82,367.26,838.82v217.86A13.28,13.28,0,0,0,380.53,1070Z"
        fill={shadow}
      />
      <path
        d="M361.26,860.85c-.19-3.67-.11-7.34.05-11s.47-7.35.86-11c.2-1.84.4-3.67.65-5.51s.49-3.67.85-5.51a44.18,44.18,0,0,1,3.59-11,43.74,43.74,0,0,1,3.58,11c.37,1.84.63,3.67.86,5.51s.45,3.67.65,5.51c.38,3.67.68,7.34.85,11s.25,7.34.06,11Z"
        fill={colors.outline}
      />
      <path
        d="M632.74,870v8c.26,34,.26,69,0,102.75,0,2.87,0,5.72,0,8.53v67.41A13.28,13.28,0,0,1,619.47,1070H380.53a13.28,13.28,0,0,1-13.27-13.28V998.52c0-2.51,0-5.07,0-7.65-.25-34.87-.25-69.87,0-105.3V860.85"
        fill="none"
        stroke={colors.outline}
        strokeMiterlimit={10}
        strokeWidth="12px"
      />
      <path
        d="M626.74,870c-.19-4.17-.1-8.35.06-12.53s.47-8.35.85-12.53c.2-2.09.41-4.18.65-6.27s.49-4.17.86-6.26a54.55,54.55,0,0,1,3.58-12.53,55.09,55.09,0,0,1,3.59,12.53c.36,2.09.62,4.18.85,6.26s.45,4.18.65,6.27c.38,4.18.69,8.35.85,12.53s.25,8.36.06,12.53Z"
        fill={colors.outline}
      />
      <path
        d="M380.53,758.82l233.23-.1"
        fill="none"
        stroke={colors.outline}
        strokeMiterlimit={10}
        strokeWidth="12px"
      />
      <path
        d="M559.55,758.82a62.78,62.78,0,0,1-1.44,13.39,60.92,60.92,0,0,1-1.74,6.33,50.88,50.88,0,0,1-10,16.83,48.2,48.2,0,0,1-4.5,4.43c-25.17,18.2-65.17,19.2-89.48-.58a51.79,51.79,0,0,1-7.11-7.6,50.84,50.84,0,0,1-9.1-19.41,62.41,62.41,0,0,1-1.07-6.59,61,61,0,0,1-.37-6.8"
        fill="none"
        stroke={colors.outline}
        strokeMiterlimit={10}
        strokeWidth="12px"
      />
      <path
        d="M503.4,828.39a66.61,66.61,0,0,1-18.39,1.1,69.79,69.79,0,0,1-18.2-3.76,61.46,61.46,0,0,1-16.5-8.64l-1.86-1.41c-.61-.48-1.2-1-1.79-1.52a32.24,32.24,0,0,1-3.33-3.28,43.83,43.83,0,0,1-5.38-7.51,87.37,87.37,0,0,0,6.74,6.09c.58.48,1.17.95,1.75,1.4s1.23.82,1.84,1.24,1.22.84,1.85,1.23l1.91,1.16a88.05,88.05,0,0,0,16.1,7.46,106.26,106.26,0,0,0,17.25,4.45A173.7,173.7,0,0,0,503.4,828.39Z"
        fill={colors.outline}
      />
      <path
        d="M307.83,892.5a24.54,24.54,0,0,0,3.68,2.77"
        fill="none"
        stroke={colors.outline}
        strokeLinecap="square"
        strokeMiterlimit={10}
        strokeWidth="12px"
      />
      <path
        d="M674.7,882.69a19.88,19.88,0,0,1-6.76,2.52,34.83,34.83,0,0,1-7.2.65,40.16,40.16,0,0,1-7.18-.79c-1.18-.21-2.33-.56-3.49-.87s-2.27-.77-3.37-1.24c1.21,0,2.38.18,3.56.28l3.52.29c1.17,0,2.33.2,3.5.21s2.32.11,3.48.12q3.5.06,7-.19A49.31,49.31,0,0,0,674.7,882.69Z"
        fill={colors.outline}
      />
      <Graphic />
    </>
  )
}
