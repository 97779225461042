import React from 'react'
import { useTheme } from '../../themeContext'
import { HairProps } from './types'

export const Back = ({ hairColor }: HairProps) => {
  const { colors } = useTheme()

  const { base, shadow } = colors.hair[hairColor]

  return (
    <>
      <path
        d="M501.71,209.52c-166.14,0-300.83,134.68-300.83,300.83V673.78c9.48,10.52,25.37,19.57,46,27.19L260.2,661.2l14.46,48.46c152.76,40.64,466.71,25.28,527.88-35.88V510.35C802.54,344.2,667.85,209.52,501.71,209.52Z"
        fill={base}
      />
      <path
        d="M242.87,699.45c1.32.51,2.65,1,4,1.52L260.2,661.2l14.46,48.46c45.22,12,104.55,19.15,167.28,21.63l27.28-26.22L235.81,529.43S229.67,630.88,242.87,699.45Z"
        fill={shadow}
      />
      <path
        d="M617.2,552.78l25.31,170.76c34.8-4.34,66.62-10.27,93.11-17.73h0s33.09-36.89,29.52-176.38S617.2,552.78,617.2,552.78Z"
        fill={shadow}
      />
      <path
        d="M501.71,209.52c-166.14,0-300.83,134.68-300.83,300.83V673.78c9.48,10.52,25.37,19.57,46,27.19L260.2,661.2l14.46,48.46c152.76,40.64,466.71,25.28,527.88-35.88V510.35C802.54,344.2,667.85,209.52,501.71,209.52Z"
        fill="none"
        stroke={colors.outline}
        strokeMiterlimit={10}
        strokeWidth="12px"
      />
    </>
  )
}

export const Front = ({ hairColor }: HairProps) => {
  const { colors, skin } = useTheme()

  const { base } = colors.hair[hairColor]

  return (
    <>
      <rect
        x="290.23"
        y="414.02"
        width="439.24"
        height="64.15"
        fill={skin.shadow}
      />
      <path
        d="M710,667.66q-4.71,5.82-9.71,11.37C697.79,681.8,702.87,677.91,710,667.66Zm15-316.55C694.5,296.7,641.5,259.24,580.22,242l-6.35-1.71c-22.7-6.28-46.62-7-71.32-7-26.69,0-52.46,2.74-76.77,10l-4.26,1.27A283.11,283.11,0,0,0,332.67,294q-4.37,3.58-8.57,7.32c-28.34,23.69-50.34,53.69-65.47,87.9q-2.2,4.92-4.16,9.93a212.31,212.31,0,0,0-9.41,30.61C213,556.34,262.89,634,307.17,681.6c4.37,4.7-57.93-101.85-17.42-216,7.73-.29,20.24-.51,36.19-.67l15.5-50.61,17.44,50.38c27.4-.13,59.88-.15,93.88-.12l12.32-52.36L479,464.6c74.37.14,151.44.52,195.28.77l13.2-52.17,15,52.33,8.21.05C760.78,572,728.5,641.13,710,667.66A265.62,265.62,0,0,0,769.3,500C769.3,413.58,725,351.11,725,351.11Z"
        fill={base}
      />
      <path
        d="M710,667.66q-4.71,5.82-9.71,11.37C697.79,681.8,702.87,677.91,710,667.66Zm15-316.55C694.5,296.7,641.5,259.24,580.22,242l-6.35-1.71c-22.7-6.28-46.62-7-71.32-7-26.69,0-52.46,2.74-76.77,10l-4.26,1.27A283.11,283.11,0,0,0,332.67,294q-4.37,3.58-8.57,7.32c-28.34,23.69-50.34,53.69-65.47,87.9q-2.2,4.92-4.16,9.93a212.31,212.31,0,0,0-9.41,30.61C213,556.34,262.89,634,307.17,681.6c4.37,4.7-57.93-101.85-17.42-216,7.73-.29,20.24-.51,36.19-.67l15.5-50.61,17.44,50.38c27.4-.13,59.88-.15,93.88-.12l12.32-52.36L479,464.6c74.37.14,151.44.52,195.28.77l13.2-52.17,15,52.33,8.21.05C760.78,572,728.5,641.13,710,667.66A265.62,265.62,0,0,0,769.3,500C769.3,413.58,725,351.11,725,351.11Z"
        fill="none"
        stroke={colors.outline}
        strokeMiterlimit={10}
        strokeWidth="12px"
      />
      <path
        d="M584.15,228l-6.47-1.74c-25.21-7-50.93-7.55-75.13-7.55-30.59,0-57.07,3.49-80.91,10.64l-4.26,1.27-.49.15a299.89,299.89,0,0,0-93.42,52c-3,2.46-6,5-8.84,7.55-29.63,24.82-52.93,56.11-69.24,93-1.56,3.49-3,7.05-4.42,10.57-4.18,10.69-21.29,59.77-23.75,70.65l72.53,1c7.73-.29,20.24-.51,36.19-.67l15.5-50.61,17.44,50.38c27.4-.13,59.88-.15,93.88-.12l12.32-52.36L479,464.6c74.37.14,151.44.52,195.28.77l13.2-52.17,15,52.33,8.21.05L783.8,500c0-86.19-41.3-149-46.48-156.6C706,288,651.63,247,584.15,228ZM750.31,547.25c-3.17-25.92-11.17-55.26-26.51-87.84l-3.89-8.27-6.49,0-26.78-93.63L663,450.81c-40.43-.22-107.06-.54-172.86-.69l-25.95-97.57-22.94,97.5c-27.4,0-51.55,0-72.09.09L340.6,367.53l-25.42,83c-11.45.15-20.16.34-26,.56l-9.83.38-3.29,9.26C261.72,501.22,257.92,545,264.76,591c-15.9-40-22.46-91.39-5.65-157.63l.09-.38A197.7,197.7,0,0,1,268,404.47c1.2-3.09,2.51-6.22,3.91-9.35,14.54-32.86,35.24-60.67,61.51-82.63l.33-.29c2.65-2.35,5.39-4.69,8.14-6.95a270.41,270.41,0,0,1,84-46.87l4.05-1.2c21.12-6.34,44.87-9.43,72.6-9.43,22.22,0,45.73.51,67.45,6.52l6.38,1.71c60.24,17,108.53,53.27,136,102.23l.37.66.44.63c.42.59,41.61,59.63,41.61,140.5A252.08,252.08,0,0,1,750.31,547.25Z"
        fill={base}
      />
      <path
        d="M710,667.66q-4.71,5.82-9.71,11.37C697.79,681.8,702.87,677.91,710,667.66ZM307.17,681.6c4.37,4.7-57.93-101.85-17.42-216,7.73-.29,20.24-.51,36.19-.67l15.5-50.61,17.44,50.38c27.4-.13,59.88-.15,93.88-.12l12.32-52.36L479,464.6c74.37.14,151.44.52,195.28.77l13.2-52.17,15,52.33,8.21.05C760.78,572,728.5,641.13,710,667.66"
        fill="none"
        stroke={colors.outline}
        strokeMiterlimit={10}
        strokeWidth="12px"
      />
      <path
        d="M284.48,462.85c.18-.75.33-1.32.53-1.94s.4-1.2.62-1.78c.46-1.17,1-2.31,1.51-3.42a48.48,48.48,0,0,1,3.85-6.42,37.51,37.51,0,0,1,4.88-5.75,17,17,0,0,1,7-4,31,31,0,0,1-.87,7.68c-.28,1.14-.59,2.25-.93,3.32l-1,3.25q-1,3.23-2,6.39c-.33,1.06-.68,2.09-1,3.13-.17.51-.37,1-.56,1.53s-.39,1-.56,1.4Z"
        fill={colors.outline}
      />
      <path
        d="M710.68,459.58a35,35,0,0,1,6.23.06,60.73,60.73,0,0,1,6.24.86c1,.2,2.08.4,3.12.64a25.74,25.74,0,0,1,3.11.86,18.53,18.53,0,0,1,6.24,3.58,18.74,18.74,0,0,1-6.24,3.59,25.68,25.68,0,0,1-3.11.85c-1,.25-2.08.45-3.12.65q-3.12.58-6.24.86a35,35,0,0,1-6.23.05Z"
        fill={colors.outline}
      />
      <path
        d="M444.84,452.07a134.88,134.88,0,0,1,1.49-14.25c.72-4.7,1.6-9.37,2.61-14s2.15-9.25,3.44-13.83A132.09,132.09,0,0,1,457,396.4a131.77,131.77,0,0,1-1.5,14.25q-1.08,7.05-2.61,14t-3.44,13.83A137,137,0,0,1,444.84,452.07Z"
        fill={colors.white}
      />
      <path
        d="M668,452.07a134.88,134.88,0,0,1,1.49-14.25q1.09-7.05,2.61-14T675.49,410a132.09,132.09,0,0,1,4.57-13.58,131.76,131.76,0,0,1-1.49,14.25q-1.1,7.05-2.61,14c-1,4.64-2.16,9.25-3.45,13.83A134.48,134.48,0,0,1,668,452.07Z"
        fill={colors.white}
      />
      <path d="M237.55,468.26a143,143,0,0,1,11.42-38" fill={base} />
      <path
        d="M231.6,467.51c.09-1,.18-1.85.32-2.73s.29-1.72.46-2.58c.35-1.7.75-3.38,1.23-5a78.48,78.48,0,0,1,3.47-9.75,66.09,66.09,0,0,1,4.7-9.22,29.18,29.18,0,0,1,7.19-7.91,50.46,50.46,0,0,1-.48,10.43c-.25,1.63-.51,3.25-.83,4.84s-.58,3.19-.83,4.78l-1.63,9.49-.83,4.68c-.12.78-.28,1.56-.42,2.32s-.3,1.57-.44,2.19Z"
        fill={colors.outline}
      />
      <path d="M769.33,496s-.46-20.78-8.37-47.63" fill={base} />
      <path
        d="M763.33,496.12l-.24-2.76c-.08-1-.16-1.93-.22-2.89l-.45-5.84-.91-11.8c-.3-3.95-.71-7.91-1-11.93-.08-2-.16-4.06-.1-6.14a47.58,47.58,0,0,1,.5-6.4,42.78,42.78,0,0,1,6.58,10.89,62.13,62.13,0,0,1,2.17,5.84c.67,2,1.24,4,1.81,6a111.89,111.89,0,0,1,2.69,12.17c.33,2.05.63,4.12.81,6.21q.16,1.56.24,3.15c.06,1.07.08,2.07.07,3.28Z"
        fill={colors.outline}
      />
      <path
        d="M506.7,235.5a369.62,369.62,0,0,0-42.48,4.93c-3.48.68-7,1.42-10.44,2.06s-6.9,1.61-10.35,2.41l-10.26,2.72c-3.4,1-6.77,2.08-10.15,3.12a314.87,314.87,0,0,0-39.44,15.73,348.17,348.17,0,0,0-37.24,21,183.41,183.41,0,0,1,35.08-25.14A219.23,219.23,0,0,1,421.18,245c3.48-1,6.94-2.09,10.42-3.05s7-1.82,10.55-2.6,7.1-1.4,10.65-2,7.16-1.07,10.74-1.52A190,190,0,0,1,506.7,235.5Z"
        fill={colors.white}
      />
    </>
  )
}

export const hatScale = 1.12
