import React from 'react'
import { useTheme } from '../../themeContext'
import { HairProps } from './types'
import { Noop } from '../../utils/Noop'

export const Back = Noop

export const Front = ({ hairColor }: HairProps) => {
  const { colors } = useTheme()

  const { base, shadow } = colors.hair[hairColor]

  return (
    <>
      <path
        d="M500,233.25c-135.7,0-247.73,101.33-264.56,232.45a307.11,307.11,0,0,0,70.16-98.91A919.45,919.45,0,0,0,500,387.94a899.57,899.57,0,0,0,194.4-21.15,307.11,307.11,0,0,0,70.16,98.91C747.73,334.58,635.7,233.25,500,233.25Z"
        fill={base}
      />
      <path
        d="M305.6,366.79q15.94,3.45,32.07,6.35c10.61-35.4,27.23-73.88,51.13-115.66h0c-1.33.62-2.66,1.24-4,1.87l-.17.08c-1.31.63-2.62,1.27-3.92,1.93l-.59.29c-1.22.61-2.44,1.24-3.65,1.88l-1,.53c-3.76,2-7.48,4.07-11.13,6.23l-.8.47-.14.09q-1.73,1-3.42,2.07l-.87.54q-5.36,3.35-10.55,6.93l-1.08.75c-1,.71-2,1.42-3,2.14l-.71.52-.15.1q-3.27,2.39-6.46,4.84l-.62.48c-1,.78-2,1.58-3,2.39l-1.17.94-2.41,2-.78.65c-2.05,1.71-4.06,3.45-6.06,5.22l-1.14,1-2.69,2.45L318,305q-4.28,4-8.37,8.17l-1,1c-.22.22-.43.44-.64.67-.7.72-1.39,1.44-2.08,2.17-.46.49-.92,1-1.37,1.48q-2.06,2.2-4.06,4.46l-.48.55c-.31.35-.63.7-.94,1.06L297,327c-.11.14-.22.27-.34.4l-1.2,1.43c-.61.72-1.21,1.45-1.81,2.18-.39.47-.78,1-1.17,1.44l-.66.82q-1.59,2-3.13,4l-.07.09c-.53.68-1.05,1.36-1.57,2.05-.4.52-.79,1.05-1.17,1.57l-1,1.33-1.22,1.68-1,1.35-1.41,2c-.57.81-1.13,1.62-1.69,2.44s-1.11,1.64-1.65,2.46l-.25.36-1.52,2.31-1.74,2.72c-.52.83-1,1.66-1.55,2.49h0c-.57.92-1.14,1.84-1.7,2.77s-1,1.68-1.48,2.52-.92,1.56-1.36,2.34l-.57,1-1.13,2c-.42.77-.85,1.53-1.26,2.3-.14.25-.28.5-.41.75-.42.78-.84,1.57-1.25,2.36s-.68,1.29-1,1.94l-.84,1.63-.93,1.88c-.39.78-.78,1.55-1.16,2.34l-.82,1.71-.3.65c-.44.92-.87,1.85-1.3,2.78l-.72,1.59c-.41.91-.81,1.83-1.21,2.75l-1,2.34c-.45,1.07-.88,2.14-1.32,3.21-.32.77-.63,1.53-.94,2.3-.11.26-.21.53-.31.8-.35.88-.69,1.77-1,2.66s-.61,1.57-.9,2.36l-.16.43c-.38,1-.74,2-1.1,3.06s-.64,1.79-1,2.69c-.37,1.08-.73,2.15-1.08,3.23-.3.91-.6,1.82-.89,2.73-.35,1.09-.68,2.17-1,3.26-.28.92-.55,1.84-.82,2.77-.32,1.09-.64,2.19-.94,3.29-.26.92-.51,1.85-.75,2.78-.3,1.11-.59,2.22-.87,3.34-.24.92-.46,1.86-.68,2.79-.27,1.12-.54,2.25-.8,3.37-.21.93-.41,1.87-.61,2.8-.24,1.14-.49,2.29-.73,3.44-.18.91-.35,1.82-.52,2.74s-.39,2-.57,3l-.18,1-.33,2c-.18,1.09-.37,2.19-.54,3.29,0,.21-.07.42-.1.63q-.45,3-.84,6A307.11,307.11,0,0,0,305.6,366.79Z"
        fill={shadow}
      />
      <path
        d="M500,233.25c-135.7,0-247.73,101.33-264.56,232.45a307.11,307.11,0,0,0,70.16-98.91A919.45,919.45,0,0,0,500,387.94a899.57,899.57,0,0,0,194.4-21.15,307.11,307.11,0,0,0,70.16,98.91C747.73,334.58,635.7,233.25,500,233.25Z"
        fill="none"
        stroke={colors.outline}
        strokeMiterlimit={10}
        strokeWidth="12px"
      />
      <path
        d="M506.21,250.1c-12.57,1.68-24.94,3.38-37.16,5.76s-24.29,5.18-36.18,8.64-23.62,7.48-35.18,12.07-22.95,9.73-34.46,15.05a170,170,0,0,1,31.87-20.78,204.11,204.11,0,0,1,73.07-21.19A172.76,172.76,0,0,1,506.21,250.1Z"
        fill={colors.white}
      />
    </>
  )
}
