import React from 'react'
import { useTheme } from '../../themeContext'
import { HairProps } from './types'

export const Back = () => <></>

export const Front = ({ hairColor }: HairProps) => {
  const { colors, skin } = useTheme()

  const { base, shadow } = colors.hair[hairColor]

  return (
    <>
      <path
        d="M558.73,399.34c-12.86,24-60,37.44-63.83,31.42-4.29-6.73,37.85-55.32,13.9-106.14"
        fill={skin.shadow}
      />
      <path
        d="M784,342.85c-5.1-5.1-31.06,1.83-57.19-.23A281,281,0,0,0,505.63,228.28c-2.44-20.48-.75-37.68-2.61-40.1-2.49-3.22-15.49,12.68-18.87,31-11.91-15-32.14-23-32.72-18.8-.38,2.68,7.44,14.09,14.81,29.9-111.6,13.47-203.08,92.26-235.08,197-13.86,11.92-26.86,20.34-27,25.69-.27,9.92,39.31,9.36,56.58,7.82v-.06c60.65-2,179-13.78,236.42-72.89C491.64,406.93,481.47,421,483.43,424c3,4.67,38.32-5.19,50.07-23.24,48,19.59,139.49,43.16,219-16.16l-.22-.43C766.77,372.06,790.35,349.25,784,342.85Z"
        fill={base}
      />
      <path
        d="M726.85,348.62c-5.4.24-10.66.11-16-.14s-10.59-.67-15.87-1.26c-2.64-.29-5.28-.6-7.91-1s-5.27-.72-7.89-1.23a76.91,76.91,0,0,1-15.58-4.54,91.87,91.87,0,0,1,16-2.62c2.63-.22,5.26-.37,7.89-.48l7.88-.34c5.25-.22,10.49-.34,15.72-.45s10.48-.12,15.62,0Z"
        fill={colors.outline}
      />
      <path
        d="M236.6,430s0,.24,0,.39l-.12.53-.28,1.11c-.21.75-.43,1.52-.66,2.29-.47,1.55-1,3.14-1.54,4.75s-1.06,3.28-1.77,5a20.6,20.6,0,0,1-1.38,2.6,17.91,17.91,0,0,1-2.07,2.77,13.14,13.14,0,0,1-3.72-5.47,16.11,16.11,0,0,1-.76-2.9c-.21-1-.31-2-.43-2.95a30,30,0,0,1,0-6,24.44,24.44,0,0,1,.48-3c.12-.5.25-1,.41-1.51a7.84,7.84,0,0,1,.27-.77c.1-.28.18-.48.36-.9Z"
        fill={colors.outline}
      />
      <path
        d="M260.74,460.82v-.06c17-.56,38.63-1.9,62.14-4.84,2.63-55.92,22-121.58,65.92-198.44h0L382,254.23A282.15,282.15,0,0,0,231.16,427.31c-13.86,11.92-26.86,20.34-27,25.69C203.89,462.92,243.47,462.36,260.74,460.82Z"
        fill={shadow}
      />
      <path
        d="M784,342.85c-5.1-5.1-31.06,1.83-57.19-.23A281,281,0,0,0,505.63,228.28c-2.44-20.48-.75-37.68-2.61-40.1-2.49-3.22-15.49,12.68-18.87,31-11.91-15-32.14-23-32.72-18.8-.38,2.68,7.44,14.09,14.81,29.9-111.6,13.47-203.08,92.26-235.08,197-13.86,11.92-26.86,20.34-27,25.69-.27,9.92,39.31,9.36,56.58,7.82v-.06c60.65-2,179-13.78,236.42-72.89C491.64,406.93,481.47,421,483.43,424c3,4.67,38.32-5.19,50.07-23.24,48,19.59,139.49,43.16,219-16.16l-.22-.43C766.77,372.06,790.35,349.25,784,342.85Z"
        fill="none"
        stroke={colors.outline}
        strokeMiterlimit={10}
        strokeWidth="12px"
      />
      <path
        d="M505.93,244.31a367.42,367.42,0,0,0-42.48,4.94c-3.48.67-6.95,1.41-10.44,2.05s-6.9,1.61-10.35,2.41l-10.26,2.72c-3.4,1-6.77,2.08-10.15,3.12a314.12,314.12,0,0,0-39.43,15.73,348.31,348.31,0,0,0-37.25,21,183.85,183.85,0,0,1,35.08-25.14,219.23,219.23,0,0,1,39.76-17.29c3.48-1,6.94-2.09,10.42-3.05s7-1.82,10.55-2.6,7.1-1.4,10.66-2,7.15-1.07,10.73-1.52A190,190,0,0,1,505.93,244.31Z"
        fill={colors.white}
      />
    </>
  )
}
