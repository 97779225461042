import React from 'react'
import { useTheme } from '../../themeContext'
import { ClothingProps } from './types'
import { Noop } from '../../utils/Noop'

export const TankTop = ({ color, graphic: Graphic = Noop }: ClothingProps) => {
  const { colors } = useTheme()

  const { base, shadow } = colors.clothing[color]

  return (
    <>
      <path
        d="M613.76,758.16H559.55v.1H589a175.21,175.21,0,0,1-2.12,27.22q-1,6.57-2.57,12.88a116.51,116.51,0,0,1-14.71,34.22,92,92,0,0,1-6.61,9c-37.06,37-95.95,39-131.74-1.19A98.26,98.26,0,0,1,420.8,825a124.07,124.07,0,0,1-13.4-39.48q-1-6.57-1.58-13.39c-.35-4.54-.54-9.15-.54-13.82h29.47v-.1H380.53c.82,22.29-1.59,75.64-9.11,84.48l-4.16,213.48a13.27,13.27,0,0,0,13.27,13.27H619.47a13.27,13.27,0,0,0,13.27-13.27l-2.38-215.36C620.61,840.76,613.42,783.07,613.76,758.16Z"
        fill={base}
      />
      <polygon
        points="632.74 831.31 609.95 869.4 621.34 999.43 632.94 999.43 632.94 893.78 632.74 831.31"
        fill={shadow}
      />
      <path
        d="M380.53,1069.39H497.15c-109.2,0-100.91-231.14-129.89-231.14v217.87A13.27,13.27,0,0,0,380.53,1069.39Z"
        fill={shadow}
      />
      <path
        d="M361.26,860.4a63.71,63.71,0,0,1-.1-8.29q.19-4.15.71-8.31c.18-1.39.35-2.77.57-4.16s.44-2.77.78-4.16a27.84,27.84,0,0,1,3.44-8.35,27.54,27.54,0,0,1,3.73,8.22c.39,1.38.68,2.76.93,4.13s.5,2.76.72,4.14c.44,2.75.79,5.51,1,8.27a62,62,0,0,1,.21,8.29Z"
        fill={colors.outline}
      />
      <path
        d="M632.74,869.4v8c.26,34,.26,69,0,102.76,0,2.87,0,5.72,0,8.53v67.41a13.27,13.27,0,0,1-13.27,13.27H380.53a13.27,13.27,0,0,1-13.27-13.27V998q0-3.76,0-7.65c-.25-34.86-.25-69.86,0-105.3V860.29"
        fill="none"
        stroke={colors.outline}
        strokeMiterlimit={10}
        strokeWidth="12px"
      />
      <path
        d="M626.76,869.38a65.79,65.79,0,0,1,.65-8.53q.57-4.25,1.46-8.47c.3-1.41.6-2.82.94-4.22s.69-2.81,1.15-4.21a29.21,29.21,0,0,1,4.18-8.28,28.9,28.9,0,0,1,3,8.78c.26,1.45.42,2.89.55,4.33s.25,2.88.35,4.31c.18,2.87.28,5.74.25,8.59a67.65,67.65,0,0,1-.54,8.54Z"
        fill={colors.outline}
      />
      <path
        d="M368.1,833c21.58-5.27,12.43-74.73,12.43-74.73l233.23-.1s3.93,76.62,19.18,82.92"
        fill="none"
        stroke={colors.outline}
        strokeMiterlimit={10}
        strokeWidth="12px"
      />
      <path
        d="M589,758.26a175.22,175.22,0,0,1-2.11,27.22q-1,6.57-2.57,12.88a116.51,116.51,0,0,1-14.71,34.22,92,92,0,0,1-6.61,9c-37.06,37-95.95,39-131.74-1.19A98.26,98.26,0,0,1,420.8,825a124.07,124.07,0,0,1-13.4-39.48q-1-6.57-1.58-13.39c-.35-4.54-.54-9.15-.54-13.82"
        fill="none"
        stroke={colors.outline}
        strokeMiterlimit={10}
        strokeWidth="12px"
      />
      <g transform="translate(0 30)">
        <Graphic />
      </g>
    </>
  )
}
